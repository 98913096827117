import React, { useEffect } from "react"
import { MdMoneyOff } from "@react-icons/all-files/md/MdMoneyOff"
import { AiFillEdit } from "@react-icons/all-files/ai/AiFillEdit"
import { VscGithub } from "@react-icons/all-files/vsc/VscGithub"
import { VscExtensions } from "@react-icons/all-files/vsc/VscExtensions"
import { FaStream } from "@react-icons/all-files/fa/FaStream"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"

const Labs = ({ setTransparentNav }) => {
  const [ref, inView] = useInView({
    threshold: 0.75,
    initialInView: true,
  })
  useEffect(() => {
    if (inView) {
      setTransparentNav(true)
    } else {
      setTransparentNav(false)
    }
  }, [inView])
  return (
    <>
      <Seo
        title="Labs"
        description="Our labs are where we develop! We're currently developing both secret and open-source projects."
      />
      <div className={`lab`}>
        <div className={`page-heading`} ref={ref}>
          <div className={`overlay`}>
            <h1>Welcome to the Lab</h1>
          </div>
        </div>
        <div className={`page-content`}>
          <div className={`text-box`}>
            <h2>Open Lab</h2>
            <p>
              <strong>
                Integrant Open Lab is where we share with the community
              </strong>
              . Our first open lab project is still in the requirements and
              design phase, but once we have a working model that is useful, we
              will open up the repo and invite collaborators.
            </p>
          </div>
        </div>
        <div className={`page-content grey-bg`}>
          <div className={`text-box`}>
            <h2 className={`icon-title`}>
              <div className={`icon`}>
                <FaStream />
              </div>
              <div>DataStream</div>
            </h2>
            <p>
              DataStream is our{" "}
              <strong>flagship open source ETL tool project.</strong> We found
              that there is a lack of basic affordable ETL tools that can just
              shift data around. We have a long history with Microsoft SQL
              Server Integration Services which is probably the most comparable
              feature set to what we want to achieve. Other ETL tools we have
              used or considered tend to focus on Big Data and come with big
              cost and infrastructure requirements.
              <br />
              <br />
              DataStream will have a <strong>modular design</strong> with a CLI
              and JSON and YAML ETL definition schema. We hope to supplement
              this with a UI that enables code generation and execution
              visualisation. We will build this using web frameworks so that it{" "}
              <strong>can be hosted anywhere</strong>, but can also be embed in
              Electron as a stand alone application.
            </p>
            <p>DataStream has the following design goals:</p>
            <div className={`design-goals d-flex flex-column flex-md-row`}>
              <div className={`design-goal`}>
                <MdMoneyOff />
                &nbsp;Free to use
              </div>
              <div className={`design-goal`}>
                <AiFillEdit />
                &nbsp;Simple to use
              </div>
              <div className={`design-goal`}>
                <VscGithub />
                &nbsp;Source control friendly
              </div>
              <div className={`design-goal`}>
                <VscExtensions />
                &nbsp;Extensible
              </div>
            </div>
          </div>
        </div>
        <div className={`page-content dark-bg`}>
          <div className={`text-box white-color`}>
            <h2 className={`white-color`}>Secret Lab</h2>
            <p>
              What goes on here? That's a <i>secret</i>.<br />
              <br />
              But we will share our roadmap as projects start to get some legs.
              We will also spin off a tech blog as a result of our "secret
              work".
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Labs
